<template>
    <div>
        <el-button size="mini" @click="addTaxSystem" style="margin: 15px"
          >Добавить</el-button
        >
        <el-table :data="taxSystems" style="width: 100%" size="mini">
          <el-table-column label="Дата" header-align="center" align="center">
            <template slot-scope="scope">
              <el-date-picker
                v-model="scope.row.StartDate"
                type="date"
                format="dd.MM.yyyy"
                value-format="yyyy-MM-dd"
                :disabled="scope.row.Id && true"
              ></el-date-picker>
            </template>
          </el-table-column>
          <el-table-column label="СНО" header-align="center" align="center">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.TypeId"
                :disabled="scope.row.Id && true"
              >
                <el-option
                  v-for="item in TaxSystemList"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Id"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            label="Действие"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="saveTaxSystem(scope.row)"
                v-if="!scope.row.Id"
                >Сохранить</el-button
              >
              <el-button
                size="mini"
                @click="cancelTaxSystem()"
                v-if="!scope.row.Id"
                >Отмена</el-button
              >
            </template>
          </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
  name: "ShopSystemSettings",
  data: function () {
    return {
      fullScreenLoading: false,
      taxSystems: [],
      TaxSystemList: [],
    };
  },
  methods: {
    loadTaxSystem() {
      var self = this;
      var settingID = this.$route.query.partnerID;
      this.axios
        .post("/ShopEdit/getShopTaxSystem", {
          settingId: settingID,
        })
        .then(function (response) {
          self.taxSystems = response.data.Data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    cancelTaxSystem() {
      this.loadTaxSystem();
    },
    addTaxSystem() {
      var taxSystem = {};
      this.taxSystems = [...this.taxSystems, taxSystem];
    },
    saveTaxSystem(taxSystem) {
      if (taxSystem) {
        var settingID = this.$route.query.partnerID;
        var self = this;
        this.axios
          .post("/ShopEdit/addShopTaxSystem", {
            taxSystem: taxSystem,
            settingId: settingID,
          })
          .then(function (response) {
            self.showSuccess("Успешно");
            self.loadTaxSystem();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    startLoading() {
      this.fullScreenLoading = true;
    },
    stopLoading() {
      this.fullScreenLoading = false;
    },
    loadTaxSystemsList(){
      var self = this;
      var settingID = this.$route.query.partnerID;   
      this.startLoading();
      self.axios
        .post("/ShopEdit/getTaxSystems", { })
        .then(function (response) {
          self.stopLoading();
          self.TaxSystemList = response.data.TaxSystems;
        })
        .catch(function (error) {
          self.stopLoading();
          console.log(error);
        });

    },
    showSuccess(message) {
      this.$message({
        message: message,
        type: "success",
      });
    },
    showError(message) {
      this.$message.error(message);
    },
  },
  mounted: function () {
    this.loadTaxSystemsList();
    this.loadTaxSystem();
  },
};
</script>

<style scoped>
</style>