<template>
    <div>
        <el-button size="mini" @click="addTariffRise"
          >Добавить подъем</el-button
        >
        <el-table
          :data="shopTariffRise"
          ref="shopTariffRise"
          style="width: 100%"
          size="mini"
          highlight-current-row
          @current-change="handleTariffRiseSelect"
        >
          <el-table-column header-align="center" align="center" width="400">
            <template slot-scope="scope">
              <el-button
                :disabled="!scope.row.Id || scope.row.Id < 0"
                size="mini"
                @click="copyTariffRise(scope.row)"
                >Копировать в подчиненные</el-button
              >
              <el-button
                size="mini"
                @click="saveTariffRise(scope.row, scope.$index)"
                >Сохранить</el-button
              >
              <el-button
                size="mini"
                @click="deleteTariffRise(scope.row, scope.$index)"
                >Удалить</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            label="Название"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <el-input size="mini" v-model="scope.row.Name"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="Установить значение при отсутствии информации"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <el-select
                size="mini"
                v-model="scope.row.WhoPaysRise"
                placeholder="Выберите из списка"
              >
                <el-option
                  v-for="item in WhoPaysRiseList"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Id"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            label="Дата начала тарифа"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <el-date-picker
                style="width: 100%"
                v-model="scope.row.StartDateFormated"
                type="date"
                format="dd.MM.yyyy"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </template>
          </el-table-column>
          <el-table-column label="Блок" header-align="center" align="center">
            <template slot-scope="scope">
              <el-checkbox
                size="mini"
                v-model="scope.row.Readonly"
              ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            label="Расширенный режим"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <el-checkbox size="mini" v-model="scope.row.Mode"></el-checkbox>
            </template>
          </el-table-column>
        </el-table>
        <div style="width: 50%; float: left">
          <div style="text-align: center">Экипажи</div>
          <el-button size="mini" @click="addRiseWeightBreak"
            >Добавить</el-button
          >
          <el-table
            :data="shopWeightBreak"
            ref="shopWeightBreak"
            style="width: 100%"
            size="mini"
            highlight-current-row
          >
            <el-table-column header-align="center" align="center">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  icon="el-icon-delete"
                  @click="removeRiseWeightBreak(scope.$index)"
                ></el-button>
              </template>
            </el-table-column>
            <el-table-column label="Вес">
              <el-table-column
                label="От (Кг)"
                header-align="center"
                align="center"
              >
                <template slot-scope="scope">
                  <el-input
                    size="mini"
                    v-model="scope.row.WeightFrom"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column
                label="До (Кг)"
                header-align="center"
                align="center"
              >
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.WeightTo"></el-input>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="Лифт">
              <el-table-column label="Да" header-align="center" align="center">
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.LiftOn"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="Нет" header-align="center" align="center">
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.LiftOff"></el-input>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>
        <div
          style="width: 50%; float: left"
          v-show="this.showCourierWeightBreakTab()"
        >
          <div style="text-align: center">Контрагенты</div>
          <el-button size="mini" @click="addRiseCourierWeightBreak"
            >Добавить</el-button
          >
          <el-table
            :data="shopCourierWeightBreak"
            ref="shopCourierWeightBreak"
            style="width: 100%"
            size="mini"
            highlight-current-row
          >
            <el-table-column header-align="center" align="center">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  icon="el-icon-delete"
                  @click="removeRiseCourierWeightBreak(scope.$index)"
                ></el-button>
              </template>
            </el-table-column>
            <el-table-column label="Вес">
              <el-table-column
                label="От (Кг)"
                header-align="center"
                align="center"
              >
                <template slot-scope="scope">
                  <el-input
                    size="mini"
                    v-model="scope.row.WeightFrom"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column
                label="До (Кг)"
                header-align="center"
                align="center"
              >
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.WeightTo"></el-input>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="Лифт">
              <el-table-column label="Да" header-align="center" align="center">
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.LiftOn"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="Нет" header-align="center" align="center">
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.LiftOff"></el-input>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>
    </div>
</template>

<script>
export default {
  name: "ShopSystemSettings",
  data: function () {
    return {
      fullScreenLoading: false,
      selectedTariffRise: null,
      shopTariffRise: [],
      shopWeightBreak: [],
      WhoPaysRiseList: [],
      shopCourierWeightBreak: [],
    };
  },
  methods: {
    addTariffRise() {
      var settingID = this.$route.query.partnerID;
      var tariffRise = { ShopId: settingID };
      this.shopTariffRise = [...this.shopTariffRise, tariffRise];
    },
    addRiseWeightBreak() {
      var shopWeightBreak = { TypeId: 0 };
      this.shopWeightBreak = [...this.shopWeightBreak, shopWeightBreak];
    },
    addRiseCourierWeightBreak() {
      var shopCourierWeightBreak = { TypeId: 1 };
      this.shopCourierWeightBreak = [
        ...this.shopCourierWeightBreak,
        shopCourierWeightBreak,
      ];
    },
    removeRiseCourierWeightBreak(index) {
      if (this.shopCourierWeightBreak[index]) {
        this.shopCourierWeightBreak.splice(index, 1);
      }
    },
    copyTariffRise(tarRise) {
      if (tarRise) {
        var self = this;
        this.$confirm("Копировать тариф в подчиненные?", "Предупреждение", {
          confirmButtonText: "Да",
          cancelButtonText: "Отмена",
          type: "warning",
        })
          .then(() => {
            self.axios
              .post("ShopEdit/сopyTariffRise", {
                rateId: tarRise.Id,
              })
              .then(function (response) {
                self.showSuccess("Тариф скопирован");
              })
              .catch(function (error) {
                self.showError("Произошла ошибка");
              });
          })
          .catch(() => {});
      }
    },
    saveTariffRise(row, index) {
      if (this.selectedTariffRise) {
        var self = this;
        this.axios
          .post("ShopEdit/updateTariffRise", {
            header: row,
            weights: this.shopWeightBreak,
            weightCouriers: this.shopCourierWeightBreak,
          })
          .then(function (response) {
            self.showSuccess("Изменения по тарифу на подъем сохранены");
            self.$set(self.shopTariffRise, index, response.data.Data);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    removeTariffRise(index) {
      if (this.shopTariffRise[index]) {
        this.shopTariffRise.splice(index, 1);
      }
    },
    deleteTariffRise(tariffRise, index) {
      let self = this;
      this.$confirm("Удалить тариф на подъем?", "Предупреждение", {
        confirmButtonText: "Да",
        cancelButtonText: "Отмена",
        type: "warning",
      })
        .then(() => {
          if (tariffRise) {
            self.axios
              .post("/ShopEdit/deleteTariffRise", {
                rateId: tariffRise.Id,
              })
              .then(function (response) {
                self.removeTariffRise(index);
              })
              .catch(function (error) {
                console.log(error);
              });
          }
          self.removeTariffRise(index);
        })
        .catch(() => {});
    },
    showCourierWeightBreakTab() {
      if (
        this.selectedTariffRise &&
        this.selectedTariffRise.Mode
      ) {
        return true;
      }
      return false;
    },
    handleTariffRiseSelect(tariffRise) {
      this.selectedTariffRise = tariffRise;
      this.loadWeightBreaks();
    },
    loadWeightBreaks() {
      if (
        this.selectedTariffRise &&
        this.selectedTariffRise.Id
      ) {
        var self = this;
        this.axios
          .post("/ShopEdit/getWeightBreak", {
            rateId: this.selectedTariffRise.Id,
          })
          .then(function (response) {
            self.shopWeightBreak = response.data.Data.filter(
              (item) => item.TypeId === 0
            );
            self.shopCourierWeightBreak = response.data.Data.filter(
              (item) => item.TypeId !== 0
            );
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    loadTariffRise() {
      var self = this;
      var settingID = this.$route.query.partnerID;
      this.axios
        .post("/ShopEdit/getTariffRises", {
            partnerId: settingID,
        })
        .then(function (response) {
          self.shopTariffRise = response.data.Data;
          if (self.shopTariffRise && self.shopTariffRise.length > 0) {
                self.$refs.shopTariffRise.setCurrentRow(self.shopTariffRise[0]);
            }
        })
        .catch(function (error) {
          console.log(error);
        });
    },    
    startLoading() {
      this.fullScreenLoading = true;
    },
    stopLoading() {
      this.fullScreenLoading = false;
    },
    loadPayRiseWaysList(){
      var self = this;
      this.startLoading();
      self.axios
        .post("/ShopEdit/getPayRiseWays", { })
        .then(function (response) {
          self.stopLoading();
          self.WhoPaysRiseList = response.data.PayRiseWays;
        })
        .catch(function (error) {
          self.stopLoading();
          console.log(error);
        });

    },
    showSuccess(message) {
      this.$message({
        message: message,
        type: "success",
      });
    },
    showError(message) {
      this.$message.error(message);
    },
  },
  mounted: function () {
    this.loadPayRiseWaysList();
    this.loadTariffRise();
  },
};
</script>

<style scoped>
</style>